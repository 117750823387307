
import { Component, OnInit, ViewChild } from '@angular/core';
import { KDSService } from '../service/kds-service';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessagingService } from 'src/app/service/MessagingService';
import { PrintDetailsComponent } from '../print-details/print-details.component';
import { PrintKotReceiptComponent } from '../print-kot-receipt/print-kot-receipt.component';

const encryptedKey = '3249d853a10f86aa';


@Component({
	selector: 'app-kds-view',
	templateUrl: './kds-view.component.html',
	styleUrls: ['./kds-view.component.css']
})
export class KdsViewComponent implements OnInit {
	@ViewChild('printDetailRef') printDetailRef: PrintDetailsComponent
	@ViewChild('kotPrintDetailRef') kotPrintDetailRef: PrintKotReceiptComponent
	public outletId: string;
	public outletTypeId: string;
	public fromDate: string;
	public toDate: string;
	public kotBy: string;
	public orderDetails = [];
	public display: string = 'orderView';
	public selectedValue1: string = 'orderwise';
	public selectedValue2: string = 'orderwise';
	private myInterval: any;
	public showTimeSlots: boolean = false;
	public selectTime: string = null;
	public timeslots = [];
	public selectedOrder: any;
	public orderAccepted = [];
	private totalOrders = 0;
	private timer: any;
	public totalOrdersCount = 0;
	public pendingOrdersCount = 0;
	public RejectedOrdersCount = 0;
	public deliveredOrdersCount = 0;
	public deliveryPersonsData = [];
	public showdelpersons = false;
	public selectedDelPerson = null;
	private reverseTimer: any;
	public detailView = false;
	public showDetails: any;
	public startDate: any;
	public endDate: any;
	public currentDate: Date;
	public searchByOrderId: number;
	public showCurrentLink = false;
	public showCSS = false;
	public status: string = '0';
	public timeExtended = false;
	public orderTimer: any;
	public rejectReasons = [];
	public showrejpop: boolean = false;
	public allTaxDetailsForTable = [];
	savedPrinterSettings: any;
	constructor(private _service: KDSService, private confirmationService: ConfirmationService,
		private messagingService: MessagingService, private _messageService: MessageService) {
		this.currentDate = new Date();

	}
	ngOnInit() {
		this.startDate = this.currentDate;
		this.endDate = this.currentDate;
		this.getDeliveryAndSlotsDetails(false);
		document.getElementById('kdsView').click();
		this.getNewOrders();
		this.checkCancelledOrders();
		this.checkNewOrders();
		//
		this._service.getSavedPrinterSettings(0).subscribe(printerSettings => {
			if (printerSettings.Data && printerSettings.Data?.PosPrinterSetting) {
			  this.savedPrinterSettings = printerSettings.Data?.PosPrinterSetting;
			}
		  });
	}


	searchByFilter() {
		clearInterval(this.myInterval);
		this.totalOrders = 0;
		const reqParam = {
			'Id': this.searchByOrderId ? this.searchByOrderId : 0,
			'FromDate': this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
			'ToDate': this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
		}
		if (this.status) {
			reqParam['DeliveryStatus'] = parseInt(this.status);
		}
		this._service.getNewOrders(reqParam).subscribe(res => {
			this.showCurrentLink = true;
			if (res.Data && res.Data.MainOrderDetail) {
				let details = res.Data.MainOrderDetail;
				this.orderDetails = [];
				this.orderDetails = details;
				this.orderDetails = [...this.orderDetails];
				this.totalOrdersCount = details[0].TotalOrder;
				this.pendingOrdersCount = details[0].TotalPending;
				this.RejectedOrdersCount = details[0].TotalCancel;
				this.deliveredOrdersCount = details[0].TotalDelivered;
				this.orderDetails = [];
				details.forEach(element => {
					element.Delivered = +element.Delivered
					if (element.Delivered == 0) {
						element['orderedTime'] = this.setCurrentDateAndTime(element.CreatedOn);
					} else if (element.Delivered == 1) {
						element['orderedTime'] = moment(element.KotIn).format('ddd, hh:mm A');
						element['deliveredTime'] = moment(element.KotOut).format('ddd, hh:mm A');
					} else if (element.Delivered == 2) {
						element['orderedTime'] = moment(element.CreatedOn).format('ddd, hh:mm A');
					}
					element['reverseTime'] = '';
				});
				this.orderDetails = details;
			} else {
				this.orderDetails = [];
			}
			this.orderDetails = [...this.orderDetails];
		});
	}

	backToCurrentOrders() {
		this.showCurrentLink = false;
		this.searchByOrderId = null;
		this.status = '0';
		this.ngOnInit();
	}

	getNewOrders() {
		if (!this.showCurrentLink) {
			const reqParam = {
				'Id': 0,
				'FromDate': moment(this.currentDate).format('YYYY-MM-DD'),
				'ToDate': moment(this.currentDate).format('YYYY-MM-DD'),
				'DeliveryStatus': 0
			}
			this._service.getNewOrders(reqParam).subscribe(res => {
				if (res.Data && res.Data.MainOrderDetail) {
					let details = res.Data.MainOrderDetail;
					this.totalOrdersCount = details[0].TotalOrder;
					this.pendingOrdersCount = details[0].TotalPending;
					this.RejectedOrdersCount = details[0].TotalCancel;
					this.deliveredOrdersCount = details[0].TotalDelivered;
					let orderReceived = false;
					this.orderDetails = [];
					details.forEach(element => {
						element['orderedTime'] = this.setCurrentDateAndTime(element.CreatedOn);
						element['reverseTime'] = '';
						element['deliveredTime'] = '';
					});
					this.orderDetails = details;
					details.forEach(element => {
						if (element.OrderActionId == 0 && !orderReceived) {
							this.playAudio();
							orderReceived = true;
							this.showCSS = true;
						}
					});
					if (this.totalOrders != details.length) {
						this.totalOrders = details.length;
						this.orderDetails = [];
						details.forEach(element => {
							element['orderedTime'] = this.setCurrentDateAndTime(element.CreatedOn);
							element['reverseTime'] = '';
						});
						this.orderDetails = details;
						this.orderDetails.forEach(items => {
							if (items.OrderActionId == 1) {
								this.orderAccepted.push(items);
							}
						});
					}
					if (!orderReceived) {
						let audioPlayer = <HTMLMediaElement>document.getElementById('audio-song');
						audioPlayer.pause();
						this.showCSS = false;
					}
					this.orderDetails = [...this.orderDetails];
					this.orderAccepted = [...this.orderAccepted];
					this.setTimer();
				} else {
					this.orderDetails = [];
				}
				this.startTimer();
			});
		}
	}

	setCurrentDateAndTime(orderedtime) {
		let currentDate = moment().format("MM/DD/YYYY hh:mm:ss A")
		let orderTime = moment(orderedtime)
		let currentTime = moment(currentDate)
		let gap = moment.duration(currentTime.diff(orderTime))
		// let hours = gap.asHours()*60
		// return Math.abs(Math.round(hours))
		return moment({}).hours(gap.asHours()).format("H:mm:ss");
	}

	orderStatus(val: string, order) {
		this.selectedOrder = order;
		console.log('this.selectedorder', this.selectedOrder);
		let action = val == '1' ? 'Accept' : 'Decline';
		clearInterval(this.myInterval);
		this.confirmationService.confirm({
			message: 'Are you sure you want to ' + action + '?',
			header: 'Confirmation',
			accept: () => {
				if (val == '1') {
					clearInterval(this.myInterval);
					this.getDeliveryAndSlotsDetails(true);
					setTimeout(() => {
						let printBillReq = {
							OrderId: this.selectedOrder.OrderId,
							BranchID: this.selectedOrder.BranchId,
							PrinterName: 'EPSON TM-T81 Receipt'
						};
						this.printDetailRef.print(printBillReq)
					}, 1000);
					setTimeout(() => {
						const printKOT = {
							OrderId: this.selectedOrder.OrderId,
							BranchID: this.selectedOrder.BranchId,
							PrinterName: 'EPSON TM-T81 Receipt',
							KotNo: this.selectedOrder.ItemDetailsOrder[0].KOTNO
						};
						this.kotPrintDetailRef.print(printKOT)
					}, 1000)
				} else {
					const request = {
						"OrderId": this.selectedOrder.OrderId,
						"ActionId": "5",
						"FoodPrepareTimeId": 0
					}
					this._service.getAdminStatus(request).subscribe(res => {
						if (res && res.Data == 'Cancel') {
							this.selectedOrder['DeliveredStatus'] = 'Cancelled';
							this.orderDetails = [...this.orderDetails];
							this.checkNewOrders();
							this.getNewOrders();
						}
					});
				}

			},
			reject: () => {
				this.checkNewOrders();
			 }
		});
	}

	timeSlotSelected() {
		this.showTimeSlots = false;
		this.checkNewOrders();
		const selectedTime = this.selectTime;
		if (selectedTime) {
			if (this.timeExtended) {
				const reqParam = {
					"OrderId": this.selectedOrder.OrderId,
					"FoodPrepareTimeId": selectedTime['Id']
				}
				this._service.extendTime(reqParam).subscribe(res => {
					this.selectTime = null;
					if (res && res.Data == 'Save Successfully!!') {
						this.checkNewOrders();
						this.selectedOrder['FoodPrepareTimeId'] = this.selectedOrder['FoodPrepareTimeId'] + parseInt(reqParam['FoodPrepareTimeId']);
						this.orderDetails = [...this.orderDetails];

						this.setTimer();
						this._messageService.clear();
						this._messageService.add({ severity: 'success', summary: 'Success', detail: 'Time Updated Successfully!!' });
						this.timeExtended = false;
						if (this.detailView && this.showDetails["OrderId"] == this.selectedOrder.OrderId) {
							this._service.getOrdersById(this.selectedOrder.OrderId).subscribe((orderData) => {
								this.selectedOrder = null;
								if (orderData.Data && orderData.Data.MainOrderDetail[0]) {
									this.showDetails["BillNo"] = orderData.Data.MainOrderDetail[0].BillNo;
									this.showDetails["BillPreFix"] = orderData.Data.MainOrderDetail[0].BillPreFix;
									this.showDetails["PickupType"] = orderData.Data.MainOrderDetail[0].PickupType;
									this.showDetails["PickupTimeId"] = orderData.Data.MainOrderDetail[0].PickupTimeId;
									this.showDetails["TotalAmountWithTax"] = orderData.Data.MainOrderDetail[0].TotalAmountWithTax;
									this.showDetails["TotalAmount"] = orderData.Data.MainOrderDetail[0].TotalAmount;
									this.showDetails["TaxAmount"] = orderData.Data.MainOrderDetail[0].TaxAmount;
									this.showDetails["TotalQty"] = orderData.Data.MainOrderDetail[0].TotalQty;
									this.showDetails["TotalItems"] = orderData.Data.MainOrderDetail[0].TotalItems;
									this.showDetails["IsCancel"] = orderData.Data.MainOrderDetail[0].IsCancel;
									this.showDetails["BillStatus"] = orderData.Data.MainOrderDetail[0].BillStatus;
									this.showDetails["TableId"] = orderData.Data.MainOrderDetail[0].TableId;
									this.showDetails["TableName"] = orderData.Data.MainOrderDetail[0].TableName;
									this.printDetails()
									this.printKotDetails()
								}

							}, (err) => {

							})
						}
					}
				});
			}
			else {
				const request = {
					"OrderId": this.selectedOrder.OrderId,
					"ActionId": "0",
					"FoodPrepareTimeId": selectedTime['FoodPrepareTime']
				}
				this._service.getAdminStatus(request).subscribe(res => {
					this.selectTime = null;
					if (res && res.Data == 'Success') {
						this.selectedOrder['OrderActionId'] = 1;
						this.selectedOrder['OrderActionIdstatus'] = 'Item Preparation';
						this.selectedOrder['FoodPrepareTimeId'] = selectedTime['FoodPrepareTime'];
						this.selectedOrder['KotIn'] = moment(new Date()).format("MM/DD/YYYY hh:mm:ss A");
						this.orderDetails = [...this.orderDetails];
						this.orderAccepted.unshift(this.selectedOrder);
						this.orderAccepted = [...this.orderAccepted];

						if (this.detailView && this.showDetails["OrderId"] == this.selectedOrder.OrderId) {
							this._service.getOrdersById(this.selectedOrder.OrderId).subscribe((orderData) => {
								this.selectedOrder = null;
								if (orderData.Data && orderData.Data.MainOrderDetail[0]) {
									this.showDetails["BillNo"] = orderData.Data.MainOrderDetail[0].BillNo;
									this.showDetails["BillPreFix"] = orderData.Data.MainOrderDetail[0].BillPreFix;
									this.showDetails["PickupType"] = orderData.Data.MainOrderDetail[0].PickupType;
									this.showDetails["PickupTimeId"] = orderData.Data.MainOrderDetail[0].PickupTimeId;
									this.showDetails["TotalAmountWithTax"] = orderData.Data.MainOrderDetail[0].TotalAmountWithTax;
									this.showDetails["TotalAmount"] = orderData.Data.MainOrderDetail[0].TotalAmount;
									this.showDetails["TaxAmount"] = orderData.Data.MainOrderDetail[0].TaxAmount;
									this.showDetails["TotalQty"] = orderData.Data.MainOrderDetail[0].TotalQty;
									this.showDetails["TotalItems"] = orderData.Data.MainOrderDetail[0].TotalItems;
									this.showDetails["IsCancel"] = orderData.Data.MainOrderDetail[0].IsCancel;
									this.showDetails["BillStatus"] = orderData.Data.MainOrderDetail[0].BillStatus;
									this.showDetails["TableId"] = orderData.Data.MainOrderDetail[0].TableId;
									this.showDetails["TableName"] = orderData.Data.MainOrderDetail[0].TableName;
									this.printDetails()
									this.getNewOrders();
								}

							}, (err) => {

							})
						}
					}
				});
			}
		}

	}

	startTimer() {
		// for kot view time
		this.timer = setInterval(() => {
			this.orderAccepted.forEach((order: any) => {
				this.getTimediff(order);
			})
		}, 1000);
	}

	getTimediff(order) {
		const time = +new Date() - +new Date(order.CreatedOn)
		let seconds: any = ((time / 1000) % 60).toFixed(0);
		let minutes: any = ((time / (1000 * 60)) % 60).toFixed(0);
		let hours: any = ((time / (1000 * 60 * 60))).toFixed(0);
		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;
		order['timeDiff'] = minutes + ":" + seconds;
	}

	deliveryAssigned() {
		this.showdelpersons = false;
		const reqParam = {
			"OrderId": this.selectedOrder.OrderId,
			"DeliveryBoyId": this.selectedDelPerson['Id'],
			"DeliveryBoyidAction": "1"
		}
		this._service.setDeliveryPerson(reqParam).subscribe(res => {
			if (res && res.Data == '1') {
				this.selectedOrder['DeliveryBoyName'] = this.selectedDelPerson['empName'];
				this.selectedOrder['DeliveryMobileNo'] = this.selectedDelPerson['mobileno'];
				this.selectedOrder['OrderActionIdstatus'] = 'Assain';
				this.selectedOrder['OrderActionId'] = 3;
				this.orderDetails = [...this.orderDetails];
				this.selectedDelPerson = null;
			}
		});
	}

	updateAdminStatus(order, delOpen) {
		this.selectedOrder = order;
		const request = {
			"OrderId": this.selectedOrder.OrderId,
			"ActionId": "1",
			"FoodPrepareTimeId": 0
		}
		this._service.getAdminStatus(request).subscribe(res => {
			this.selectTime = '';
			if (res && res.Data == 'Ready to Delivery') {
				this.checkNewOrders();
				this.setTimer();
				this.selectedOrder['OrderActionId'] = 2;
				this.selectedOrder['OrderActionIdstatus'] = res.Data;
				this.orderDetails = [...this.orderDetails];
				this.orderAccepted = [];
				this.orderDetails.forEach(items => {
					if (items.OrderActionId == 1) {
						this.orderAccepted.push(items);
					}
				});
				this.orderAccepted = [...this.orderAccepted];
				if (delOpen) {
					this.showdelpersons = true;
					this.getNewOrders();
				} else {
					this.selectedOrder = null;
				}
			}
		});
	}

	setTimer() {
		this.reverseTimer = setInterval(() => {
			this.orderDetails.forEach(order => {
				if (order.OrderActionId == 1) {
					this.startClock(order);
				}
			})
		}, 1000)
	}

	startClock(order) {
		if (order && order.KotIn) {
			let currentDate = moment().format("MM/DD/YYYY hh:mm:ss A");
			let orderTime = moment(new Date(order['KotIn'])).format("MM/DD/YYYY hh:mm:ss A");
			let currentTime = moment(currentDate);
			let gap = moment.duration(currentTime.diff(orderTime));
			let minutes = gap.asMinutes();
			let sec = ((gap.asSeconds()) % 60);
			minutes = Math.abs(Math.round(minutes));
			sec = Math.abs(Math.round(sec));
			if (minutes < order.FoodPrepareTimeId) {
				minutes = (order.FoodPrepareTimeId - 1) - minutes;
				let min = minutes < 10 ? '0' + minutes : minutes;
				sec = 60 - sec;
				let seconds = sec < 10 ? '0' + sec : sec
				order['reverseTime'] = min + ':' + seconds;
			} else {
				order['reverseTime'] = '00' + ':' + '00';
				if (!this.timeExtended) {
					this.timeExtend(order);
				}
			}
			this.orderDetails = [...this.orderDetails];
		}
	}

	viewDetails(order) {
		this.detailView = true;
		this.showDetails = order;
		this.printDetails();
	}

	closeDetails() {
		this.detailView = false;
		this.showDetails = null;
	}

	printDetails(from?) {
		this.allTaxDetailsForTable = [];
		const numWords = require('num-words');
		this.showDetails['netAmmount'] = this.showDetails['TotalAmountWithTax'] + this.showDetails['Deliverycharge'];
		this.showDetails['amounttInWords'] = (numWords(this.showDetails['netAmmount'])).replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
		this.showDetails = { ...this.showDetails };
		this.showDetails.ItemDetailsOrder.forEach(item => {
			this.calculateItemTax(item);
		});
		this.allTaxDetailsForTable = [...this.allTaxDetailsForTable];
		//
		this._service.getSavedPrinterSettings(0).subscribe(printerSettings => {
			if (printerSettings.Data && printerSettings.Data?.PosPrinterSetting) {
			  this.savedPrinterSettings = printerSettings.Data?.PosPrinterSetting;
			  //console.log('dd', this.savedPrinterSettings)        
			}})
		let printerName = this.savedPrinterSettings.filter((printer: any) => {
			return printer.Outid == this.showDetails["OutletId"]
			  && printer.DepTypeid == this.showDetails["SubDepartmentId"]
			  && printer.Depid == this.showDetails["DepartmentId"]
		  })[0];
		  if (printerName && printerName.PosPrinterSettingunder && printerName.PosPrinterSettingunder.length > 0) {
			let printerSet = false;
			["KotPrinter",
			  "KotPrinter1",
			  "KotPrinter2",
			  "KotPrinter3",
			  "KotPrinter4",
			  "KotBackupPrinter"].forEach((printer: string) => {
	  
				if (printerName.PosPrinterSettingunder[0][printer] !== '') {
				  printerSet = true;
		//
		if (from === "print") {
			setTimeout(() => {
				let printBillReq = {
					OrderId: this.showDetails["OrderId"],
					BranchID: this.showDetails["BranchId"],
					PrinterName:printerName.PosPrinterSettingunder[0][printer],
					PrintFormat:this.showDetails["PrintFormat"]
				};
				this.printDetailRef.print(printBillReq)
			}, 1000)
		}
	}})}
	}

	printKotDetails(from?) {
		this.allTaxDetailsForTable = [];
		const numWords = require('num-words');
		this.showDetails['netAmmount'] = this.showDetails['TotalAmountWithTax'] + this.showDetails['Deliverycharge'];
		this.showDetails['amounttInWords'] = (numWords(this.showDetails['netAmmount'])).replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
		this.showDetails = { ...this.showDetails };
		this.showDetails.ItemDetailsOrder.forEach(item => {
			this.calculateItemTax(item);
		});
		this.allTaxDetailsForTable = [...this.allTaxDetailsForTable];
		//
		this._service.getSavedPrinterSettings(0).subscribe(printerSettings => {		
			if (printerSettings.Data && printerSettings.Data?.PosPrinterSetting) {
			  this.savedPrinterSettings = printerSettings.Data?.PosPrinterSetting;
			  //console.log('dd', this.savedPrinterSettings)        
			}})

		let printerName = this.savedPrinterSettings.filter((printer: any) => {
			return printer.Outid == this.showDetails["OutletId"]
			  && printer.DepTypeid == this.showDetails["SubDepartmentId"]
			  && printer.Depid == this.showDetails["DepartmentId"]
		  })[0];
		  if (printerName && printerName.PosPrinterSettingunder && printerName.PosPrinterSettingunder.length > 0) {
			let printerSet = false;
			["KotPrinter",
			  "KotPrinter1",
			  "KotPrinter2",
			  "KotPrinter3",
			  "KotPrinter4",
			  "KotBackupPrinter"].forEach((printer: string) => {
	  
				if (printerName.PosPrinterSettingunder[0][printer] !== '') {
				  printerSet = true;
		//
		if (from === "print") {
			setTimeout(() => {
				const printKOT = {
					OrderId: this.showDetails["OrderId"],
					BranchID: this.showDetails["BranchId"],
					PrinterName:printerName.PosPrinterSettingunder[0][printer],
					PrintFormat:this.showDetails["PrintFormat"],
					KotNo: this.showDetails.ItemDetailsOrder[0].KOTNO
				};
				this.kotPrintDetailRef.print(printKOT)
			}, 1000)
		}}})}
	}

	playAudio() {
		document.getElementById('kdsView').click();
		let audioPlayer = <HTMLMediaElement>document.getElementById('audio-song');
		audioPlayer.play();
	}

	deliverOrderFromKitchen(order) {
		const reqParam = {
			"OrderId": order.OrderId,
			"ActionId": "1"
		}
		this._service.setKitchenStatus(reqParam).subscribe(res => {
			if (res && res.Data && res.Data['ReturnMessage'] == 'Ready to Delivery') {
				order['OrderActionId'] = 2;
				order['OrderActionIdstatus'] = res.Data['ReturnMessage'];
				this.orderDetails = [...this.orderDetails];
				this.orderAccepted = [];
				this.orderDetails.forEach(items => {
					if (items.OrderActionId == 1) {
						this.orderAccepted.push(items);
					}
				});
				this.orderAccepted = [...this.orderAccepted];
				this.getNewOrders();
			}
		});
	}
	getDeliveryAndSlotsDetails(flag: boolean) {
		this._service.getDeliveryDetails().subscribe(response => {
			if (response && response.Data) {
				this.timeslots=[];
				this.deliveryPersonsData=[];
				response.Data.Details.forEach(element => {
					element.TimeSlot.forEach(el => {
						this.timeslots.push(el)
					});
					element.DeliveryBoyList.forEach(el => {
						this.deliveryPersonsData.push(el);
					});
				});
				this.showTimeSlots = flag;
			}
		})
	}

	timeExtend(order) {
		clearInterval(this.myInterval);
		clearInterval(this.reverseTimer);
		this.confirmationService.confirm({
			message: `Do you want to extend time or deliver item?<br> OrderID <b>${order.OrderId}</b>`,
			header: 'Confirmation',
			acceptLabel: 'Change Status',
			rejectLabel: 'Extend Time',
			acceptIcon: '',
			rejectIcon: '',
			accept: () => {
				this.updateAdminStatus(order, false);
			},
			reject: () => {
				this.getDeliveryAndSlotsDetails(true);
				this.timeExtended = true;
				this.selectedOrder = order;
			}
		});
	}

	cancelSlot() {
		this.showTimeSlots = false;
		this.timeExtended = false;
		this.selectTime = null;
		this.checkNewOrders();
	}

	cancelDelivery() {
		this.selectedDelPerson = null;
		this.showdelpersons = false;
	}

	checkNewOrders() {
		this.myInterval = setInterval(() => {
			this.getNewOrders();
			this.checkCancelledOrders();
		}, 5000);
	}

	checkCancelledOrders() {
		this._service.getDeliveryBoyCancelledOrder().subscribe();
	}

	getRejectedReasons(id) {
		this.rejectReasons = [];
		this._service.getRejectedReasons(id.toString()).subscribe(rej => {
			if (rej && rej.Data) {
				this.rejectReasons = rej.Data.OrderMenuDeliveryBoy;
				this.showrejpop = true;
			}
		})
	}

	calculateItemTax(item) {
		item.TaxAmount = 0
		let taxCount = item.TaxDetailsOrder
		if (item && taxCount && taxCount.length > 0) {
			taxCount.forEach(tax => {
				if (tax.TotalTaxRate > 0) {
					let Tax = Number((((item.TotalAmount - item.DiscountAmount) * tax.TotalTaxRate) / 100).toFixed(2));
					item.TaxAmount = item.TaxAmount + Tax
					item.TaxAmount = Number(item.TaxAmount.toFixed(2));
					let name = tax.TaxName + (tax.TaxOnId === 0 ? `(${tax.TotalTaxRate}%)` : `(${tax.TotalTaxRate}%)`)
					if (this.allTaxDetailsForTable.some(x => x.TaxName === name)) {
						var taxObj = this.allTaxDetailsForTable.find(x => x.TaxName === name)
						taxObj.TaxAmount = taxObj.TaxAmount + Tax
					} else {
						this.allTaxDetailsForTable.push({
							TaxName: name,
							TaxAmount: Tax
						});
					}
				}
			});
		}
	}
}

